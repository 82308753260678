import React from "react"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import Layout from "../components/layout"
import SEO from "../components/seo"

// destructuring assignment
// https://stackoverflow.com/a/51012767
class TagsIndex extends React.Component {
  render() {
    const { data } = this.props
    const { group } = this.props.data.allMarkdownRemark
    return (
      <Layout location={this.props.location} title={data.site.siteMetadata.title}>
      <SEO title={data.site.siteMetadata.title} />

      <div className="flex flex-col md:flex-row items-center font-serif">
        <div className="px-6 md:px-20 md:mx-20">

          <p className="text-4xl font-bold text-primary pb-6">
            <a href="/blog">Blog</a>
          </p>

          <p className="text-3xl font-bold text-primary pb-6">
            Tags
          </p>

          <ul className="pb-3 text-secondary">
            {group.map(tag => (
              <li key={tag.fieldValue}>
                <Link
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>

          <div className="py-4">
            <a href="/tags">
              <svg
                className="w-10 h-10"
                id="back"
                viewBox="0 -16 370.29 370">
                <path fill="#1ae5be" d="M271 159.15H32.1L83.41 100a10 10 0 00-15.1-13.13l-64.67 74.5a10 10 0 00.47 15.79l64.68 64.67a10 10 0 0014.14-14.11l-48.6-48.57h236.66a10 10 0 000-20zm0 0"/><path fill="#0635c9" d="M330.58.15h-134.2a39.81 39.81 0 00-40.09 39.54v35.74a10 10 0 1020 0V39.8a19.8 19.8 0 0119.95-19.64h134.34a19.47 19.47 0 0119.71 19.23V298.4a19.58 19.58 0 01-19.38 19.76H196.38a19.9 19.9 0 01-20.09-19.7v-34.97a10 10 0 00-20 0v34.9a39.92 39.92 0 0040.08 39.77h134.21a39.6 39.6 0 0039.71-39.49V39.79A39.5 39.5 0 00330.95.15h-.37zm0 0"/>
              </svg>
            </a>
          </div>

        </div>
      </div>

    </Layout>
    )
  }
}

export default TagsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
